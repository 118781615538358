import { ChakraProvider } from '@chakra-ui/react'
import { Zendesk } from './components'
import { I18nextProvider } from 'react-i18next'
import i18n from './i18n/i18n'
import { BrowserRouter } from 'react-router-dom'
import { PublicRoutes } from './routes'

function App() {
  return (
    <I18nextProvider i18n={i18n}>
        <ChakraProvider>
          <BrowserRouter>
            <PublicRoutes />
          </BrowserRouter>
        </ChakraProvider>
        <Zendesk />
    </I18nextProvider>
  )
}

export default App
