import { useEffect, useRef } from 'react'

const useWindowClosed = () => {
    const isWindowClosedRef = useRef(false)

    useEffect(() => {
        const handleWindowClosed = () => {
            isWindowClosedRef.current = true
        }

        window.addEventListener('beforeunload', handleWindowClosed)

        return () => {
            window.removeEventListener('beforeunload', handleWindowClosed)
        }
    }, [])
    
    return isWindowClosedRef
}

export default useWindowClosed