import { useEffect, useState } from 'react'
import { Box, Button, Flex, Text, useMediaQuery } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useLazyQuery } from '@apollo/client'
import { useNavigate, useLocation } from 'react-router-dom'

import { BrandingLogos, Footer, LoadingScreen } from '../../components'
import { Form } from './Form'
import { GET_LOGIN_TYPES } from '../../services/graphql'
import { GetPortal } from '../Login/getPortalTypes.d'
// import useDinamicTranslations from '../../hooks/useDinamicTranslations'
import { handleErrorSentry } from '../../utils'

interface DataRecovery {
  code?: number
  detail?: string
  success?: boolean
  name?: string
}

interface FormCompleteProps {
  status: boolean
  dataRecovery: DataRecovery | null
}

interface DataPortalProps {
  data: GetPortal | null
  loading: boolean
}

export const ForgotPassword = () => {
  const [isDesktop] = useMediaQuery('(min-width: 475px)')
  const [dataPortal, setDataPortal] = useState<DataPortalProps>({
    data: null,
    loading: true,
  })
  const [formComplete, setFormComplete] = useState<FormCompleteProps>({
    status: false,
    dataRecovery: null,
  })
  const { t } = useTranslation()
  // useDinamicTranslations(['login', 'recovery', 'transversals'])
  const navigate = useNavigate()
  const { state } = useLocation()
  const refer = state?.refer || new URLSearchParams(window.location.search).get('url')
  // const refer = 'https://testempresa1000.eclass.com'
  const [getLoginTypes, { loading: loadingLoginTypes }] = useLazyQuery(GET_LOGIN_TYPES)

  const handleRedirectHome = () => {
    if (dataPortal.data?.Portal.url) {
      window.location.href = dataPortal.data?.Portal.url
    } else {
      console.error('URL or dataPortal is undefined')
    }
  }

  const getPortal = () => {
    const validateRefer = refer.replace('login', '')
    const host = import.meta.env.PROD ? `${validateRefer}/api/1.0/portal` : '/api'
    fetch(host, {
      method: 'GET',
      mode: 'cors',
    })
      .then((response) => response.json())
      .then((response) => {
        const portalData = response.data.getPortal
        
        document.cookie = `CampusVirtual${portalData.Portal.id}=eclass`
        setDataPortal((prevState) => ({
          ...prevState,
          data: portalData,
          loading: false,
        }))
        getLoginTypes({
          variables: {
            LoginTypeInput: {
              portalId: Number(portalData.Portal.id),
            },
          },
        })
      })
      .catch((err) => {
        // console.error('Error fetching data: ', err)
        if (refer) {
          handleErrorSentry(
            Array.isArray(err) ? err[0] : err,
            'warning',
            'FortgotPassword',
            'getPortal',
            'Error al consultar getPortal',
            'Error getPortal',
          )
        }
        navigate('/Oops')
      })
  }

  useEffect(() => {
    if (dataPortal.loading) {
      getPortal()
    }
  }, [])
  if (dataPortal.loading || loadingLoginTypes || !dataPortal.data) return <LoadingScreen />

  const PortalBranding =
    dataPortal &&
    dataPortal.data &&
    dataPortal.data.PortalBranding &&
    dataPortal.data.PortalBranding
      ? dataPortal.data.PortalBranding
      : null

  document.title = `${dataPortal.data.Portal.name} - ${t('recovery:RecoveryPassword')}`
  return (
    <Flex
      display="flex"
      justifyContent="center"
      alignItems="center"
      justifyItems="center"
      paddingBottom="30px"
    >
      <Box>
        <Box width="100%" mb={isDesktop ? '30px' : '16px'}>
          <BrandingLogos showMessage={false} logos={PortalBranding?.PortalBrandingLogo} />
        </Box>
        <Box
          display="grid"
          gap="16px"
          justifyContent="center"
          marginBottom={isDesktop ? '40px' : '48px'}
          marginTop={isDesktop ? '48px' : '16px'}
          maxW="612px"
          px="16px"
          textAlign="center"
        >
          <Text fontFamily="Roboto" fontSize="24px" fontWeight="bold" color="#1A202C">
            {!formComplete.status ? t('login:LogInForgotten') : t('recovery:LogInSentEmail')}
          </Text>
          <Text
            color="#1A202C"
            fontFamily="Roboto"
            fontSize="18px"
            lineHeight="31px"
            whiteSpace="pre-wrap"
            sx={{
              textWrap: 'pretty',
            }}
          >
            {!formComplete.status ? (
              t('recovery:LogInDetails')
            ) : (
              <span
                dangerouslySetInnerHTML={{
                  __html: t('recovery:LogInEmailNewPassword', {
                    email: formComplete.dataRecovery?.detail,
                  }),
                }}
              />
            )}
          </Text>
        </Box>
        <Box justifyContent="center" display="flex">
          <Box width={isDesktop ? '339px' : '100%'} px="16px">
            {!formComplete.status ? (
              <Form
                portalId={dataPortal?.data?.Portal?.id || 0}
                setFormComplete={setFormComplete}
                url={dataPortal?.data?.Portal?.url || ''}
              />
            ) : (
              <Box display="flex" justifyContent="center">
                <Button
                  backgroundColor="#0189FF"
                  borderRadius="0.25rem"
                  colorScheme="blue"
                  fontSize="16px"
                  mt={4}
                  onClick={handleRedirectHome}
                  size="lg"
                  width="50%"
                  _hover={{ backgroundColor: '#017BE5' }}
                  _loading={{
                    backgroundColor: '#0189FF',
                    opacity: '1',
                    color: 'white',
                    cursor: 'wait',
                  }}
                >
                  {t('recovery:LogInBackLogIn')}
                </Button>
              </Box>
            )}
            <Footer showInfo={false} />
          </Box>
        </Box>
      </Box>
    </Flex>
  )
}
