import { useEffect, useState } from 'react'
import { Box, Button, Flex, Text, useMediaQuery } from '@chakra-ui/react'
import { BrandingLogos, Footer, LoadingScreen } from '../../components'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import useDinamicTranslations from '../../hooks/useDinamicTranslations'

export const PageInvalid = () => {
  const [isDesktop] = useMediaQuery('(min-width: 475px)')
  const { t } = useTranslation('page-no-found')
  useDinamicTranslations(['page-no-found'])
  const { state } = useLocation()
  const [loadingData, setLoadingData] = useState(false)

  useEffect(() => {
    if (!localStorage.logo) {
      setLoadingData(false)
    }
  }, [])

  if (loadingData) return <LoadingScreen />

  document.title = 'Page no found'

  return (
    <Flex
      display="flex"
      justifyContent="center"
      alignItems="center"
      justifyItems="center"
      paddingBottom="30px"
    >
      <Box>
        <Box width="100%" mb="30px">
          <BrandingLogos showMessage={false} />
        </Box>
        <Box
          justifyContent="center"
          textAlign="center"
          maxW="589px"
          display="grid"
          gap="16px"
          py="32px"
          px="16px"
        >
          <Text fontFamily="Roboto" fontSize="24px" fontWeight="bold" color="#1A202C">
            {t('PageNoFoundTitle')}
          </Text>
          <Text
            fontFamily="Roboto"
            fontSize="18px"
            lineHeight="31px"
            color="#1A202C"
            whiteSpace="pre-wrap"
            dangerouslySetInnerHTML={{
              __html: t('PageNoFoundDetail'),
            }}
          />
        </Box>
        <Flex justifyItems="center" justifyContent="center" display="flex">
          <Box
            width={isDesktop ? '400px' : '100%'}
            px="16px"
            justifyContent="center"
            display="flex"
          >
            <Button
              fontSize="16px"
              onClick={() => (window.location.href = state.refer)}
              colorScheme="blue"
              size="lg"
              width={isDesktop ? '50%' : '100%'}
              backgroundColor="#0189FF"
              borderRadius="0.25rem"
              mt={4}
              _hover={{ backgroundColor: '#017BE5' }}
              _loading={{
                backgroundColor: '#0189FF',
                opacity: '1',
                color: 'white',
                cursor: 'wait',
              }}
            >
              {t('PageNoFoundRedirect')}
            </Button>
          </Box>
        </Flex>
        <Box justifyContent="center" display="flex">
          <Box width={isDesktop ? '400px' : '100%'} px="16px">
            <Footer showInfo={false} />
          </Box>
        </Box>
      </Box>
    </Flex>
  )
}
