import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import httpBackend from 'i18next-http-backend'

i18n
  .use(httpBackend)
  .use(initReactI18next)
  .init({
    fallbackLng: {
      'es-ES': ['es-419'],
      es: ['es-419'],
      'es-CL': ['es-419'],
      en: ['en-EN'],
      'en-US': ['en-EN'],
      default: ['es-419'],
    },
    debug: false,
    lng: localStorage.getItem('lang') ?? 'es-ES', // Default language
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
    ns: ["login", "transversals", "recovery", "changepwd", "oops", "page-no-found"],
    // ns: [],
    defaultNS: 'login',
    // react: {
    //   useSuspense: true,
    // },
  })

export default i18n
