import { useEffect, useState } from 'react'

/**
 * @returns {object} integrations
 * @returns {boolean} load
 * @example
 * const { integrations, load } = useLoadZendesk()
 *
 * Este hook se encarga de cargar las integraciones de la plataforma
 * leyendo el localStorage, si no existen las carga desde el backend
 */

interface UseLoadZendeskProps {
  integrations: any
  load: boolean
}

export const useLoadZendesk = (): UseLoadZendeskProps => {
  const [integrations, setIntegrations] = useState(null)
  const [load, setLoad] = useState(true)

  useEffect(() => {
    getIntegration()
  }, [])

  const getIntegration = async () => {
    const refer = new URLSearchParams(window.location.search).get('url')
    const host = `${refer}/api/1.0/portal/integrations`
    await fetch(host, {
      method: 'GET',
      mode: 'cors',
    })
      .then((res) => res.json())
      .then((res) => {
        setIntegrations(res.data)
        setLoad(false)
      })
  }
  return { integrations, load }
}
