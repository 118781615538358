import { Box, Flex, Text, useMediaQuery } from '@chakra-ui/react'
import { BrandingLogos, Footer, LoadingScreen } from '../../components'
import { useTranslation } from 'react-i18next'
import { Form } from './Form'
import { GET_CHECK_PSWD_CODE } from '../../services'
import { useQuery } from '@apollo/client'
import { useParams, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { DataPortalProps } from '../../View/Login/getPortalTypes'
import useDinamicTranslations from '../../hooks/useDinamicTranslations'
import { handleErrorSentry } from '../../utils'

export const ChangePassword = () => {
  const [isDesktop] = useMediaQuery('(min-width: 475px)')
  const { t } = useTranslation()
  const { token } = useParams()
  const navigate = useNavigate()
  const [dataPortal, setDataPortal] = useState<DataPortalProps>({
    data: null,
    loading: true,
  })
  const [portalId, setportalId] = useState(0)
  const [url, setUrl] = useState('')

  const {
    data,
    loading: loadingCheck,
  } = useQuery(GET_CHECK_PSWD_CODE, {
    variables: {
      LoginCheckPasswordCodeInput: {
        passwordCode: token,
      },
    },
  })
  useDinamicTranslations(['changepwd', 'recovery', 'transversals'])

  const getPortal = () => {
    const urlParams = new URLSearchParams(window.location.search)
    const refer = urlParams.get('redirect_url')
    const host = import.meta.env.PROD ? `${refer}/api/1.0/portal` : '/api'
    fetch(host, {
      method: 'GET',
      mode: 'cors',
    })
      .then((response) => response.json())
      .then((response) => {
        const portalData = response.data.getPortal

        document.cookie = `CampusVirtual${portalData.Portal.id}=eclass`
        setDataPortal((prevState) => ({
          ...prevState,
          data: portalData,
          loading: false,
        }))
        setportalId(portalData.Portal.id)
        setUrl(portalData.Portal.url)
      })
      .catch((err) => {
        // console.error('Error fetching data: ', err)
        if (refer) {
          handleErrorSentry(
            Array.isArray(err) ? err[0] : err,
            'warning',
            'ChangePassword',
            'getPortal',
            'Error al consultar getPortal',
            'Error getPortal',
          )
        }
        navigate('/Oops')
      })
  }

  useEffect(() => {
    if (dataPortal.loading) {
      getPortal()
    } else {
      if (data && data.checkPasswordCode.status.success === false) {
        navigate('/page-no-found', { state: { refer: dataPortal.data?.Portal.url } })
      }
    }
  }, [data, dataPortal.loading])

  if (loadingCheck || dataPortal.loading || data && data.checkPasswordCode.status.success === false) return <LoadingScreen />

  document.title = dataPortal.data?.Portal.name + ` - ${t('changepwd:ChangePassword')}`
  const PortalBranding =
    dataPortal &&
    dataPortal.data &&
    dataPortal.data.PortalBranding &&
    dataPortal.data.PortalBranding
      ? dataPortal.data.PortalBranding
      : null

  return (
    <Flex
      display="flex"
      justifyContent="center"
      alignItems="center"
      justifyItems="center"
      paddingBottom="30px"
    >
      <Box>
        <Box width="100%" mb="30px">
          <BrandingLogos showMessage={false} logos={PortalBranding && PortalBranding?.PortalBrandingLogo}/>
        </Box>
        <Box
          justifyContent="center"
          textAlign="center"
          maxW="589px"
          display="grid"
          gap="16px"
          py="32px"
          px="16px"
        >
          <Text fontFamily="Roboto" fontSize="24px" fontWeight="bold" color="#1A202C">
            {t('changepwd:LogInCreateNew')}
          </Text>
          <Text
            fontFamily="Roboto"
            fontSize="18px"
            lineHeight="31px"
            color="#1A202C"
            whiteSpace="pre-wrap"
            dangerouslySetInnerHTML={{ __html: t('changepwd:LogInNewMust') }}
          />
        </Box>
        <Box justifyContent="center" display="flex">
          <Box width={isDesktop ? '400px' : '100%'} px="16px">
            <Form portalId={portalId} url={url} />
            <Footer showInfo={false} />
          </Box>
        </Box>
      </Box>
    </Flex>
  )
}
