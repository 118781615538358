import { Box, Text, Link } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

export const WithSignup = () => {
  const { t } = useTranslation('login')
  // const refer = document.referrer.replace('login', '')
  const refer = new URLSearchParams(window.location.search).get('url')
  return (
    <Box textAlign="center" display="flex">
      <Text
        fontSize="14px"
        color="#808080"
        fontWeight="400"
        fontFamily="Roboto"
        lineHeight="1"
        marginBottom="35px"
        textAlign="center"
      >
        {t('loginWithSignup')}
      </Text>
      <Link
        marginLeft="4px"
        textDecor="underline"
        color="#0189FF"
        fontSize="14px"
        fontWeight="500"
        fontFamily="Roboto"
        lineHeight="1"
        marginBottom="35px"
        textAlign="center"
        href={`${refer}/sign/register`}
      >{t('loginsignup')}</Link>
    </Box>
  )
}
