import { ChangeEvent, FC, useEffect } from 'react'
import { Box, Select, FormLabel } from '@chakra-ui/react'
import { AcordionClosed } from '../../../assets'
import { useLazyQuery } from '@apollo/client'
import { GET_LOGIN_TYPES } from '../../../services/graphql'

import { useTranslation } from 'react-i18next'

/*
 * SelectLogin component
 * Este componente representa el select de tipos de logueo
 * Muestra los tipos de logueo disponibles
 * TODO: Validar si se necesita diccionarios y como
 */

interface SelectLoginProps {
  handleSelectChange?: (e: ChangeEvent<HTMLSelectElement>) => void
  portalId?: any
}

export const SelectLogin: FC<SelectLoginProps> = ({ handleSelectChange, portalId }) => {

  const [getTypes, { data, loading }] = useLazyQuery(GET_LOGIN_TYPES)
  const { t } = useTranslation('login')

  const loginTypesSchema = {
    1: 'RUT',
    2: 'USERNAME',
    3: 'EMAIL',
    4: 'CC',
    5: 'DNI',
    6: 'PASSPORT',
    9: 'CPF',
    12: 'FOTOCHECK',
    13: 'CI',
    14: 'RUC',
    15: 'CE',
    16: 'NIT',
    17: 'DPI',
    18: 'DRIVERLICENSE',
    19: 'DUI',
    20: 'CURP',
  }

  useEffect(() => {
    getTypes({
      variables: {
        LoginTypeInput: {
          portalId: Number(portalId),
        },
      },
    })
  }, [])

  if (
    !data ||
    !data.getLoginTypes ||
    !data.getLoginTypes.status ||
    data.getLoginTypes.status.success === false ||
    loading
  )
    return null
  const getLoadingTypes = data.getLoginTypes
  return (
    <Box fontFamily="Roboto">
      <FormLabel fontSize="sm" fontFamily="Roboto" fontWeight="bold" color="#1A202C">
        {t('LogInType')}
      </FormLabel>
      <Select
        onChange={handleSelectChange}
        placeholder={t('LogInSelect')}
        borderRadius="5px"
        icon={<AcordionClosed />}
        iconSize="14px"
        border="1px solid #C9C9D3"
        size="lg"
        fontSize="1rem"
        fontFamily="Roboto"
        _focus={{
          boxShadow: 'none',
          borderColor: '#0189FF',
        }}
        _hover={{
          borderColor: '#0189FF',
          borderWidth: '1px',
        }}
        aria-describedby="document-type-helper"
      >
        {getLoadingTypes.LoginTypes.map((type: any) => (
          <option key={type.id} value={loginTypesSchema[type.id]}>
            {/* {type.id} -
            {loginTypesSchema[type.id]} - */}
            {type.name}
          </option>
        ))}
      </Select>
    </Box>
  )
}
