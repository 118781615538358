import { Input, FormLabel, Box, Link } from '@chakra-ui/react'
import { FC, ChangeEvent } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

interface InputLoginProps {
  type?: string
  placeholder?: string
  isDisabled?: boolean
  forgotPassword?: boolean
  text: string
  describedby?: string
  handleInputChange?: (e: ChangeEvent<HTMLInputElement>) => void
}

export const InputLogin: FC<InputLoginProps> = ({
  type = 'text',
  text,
  isDisabled = false,
  placeholder = '',
  forgotPassword = false,
  describedby,
  handleInputChange,
}) => {
  const { t } = useTranslation('login')
  const navigate = useNavigate()
  // const refer = document.referrer
  const refer = new URLSearchParams(window.location.search).get('url')
  const url = window.location.search
  return (
    <Box
      sx={{
        'input > * ': {
          opacity: 1,
        },
      }}
    >
      <Box display="flex" width="100%" justifyContent="space-between">
        <FormLabel
          fontSize="sm"
          fontFamily="Roboto"
          fontWeight="bold"
          color={isDisabled ? '#999999' : '#1A202C'}
        >
          {text}
        </FormLabel>
        {forgotPassword && (
          <Link
            fontSize="12px"
            color="#0189FF"
            fontWeight="500"
            fontFamily="Roboto"
            textDecor="underline"
            onClick={() => navigate(`/recovery-password/${url}`, { state: { refer } })}
            _hover={{ textDecor: 'none' }}
            tabIndex={0}
          >
            {t('LogInForgotten')}
          </Link>
        )}
      </Box>
      <Input
        type={type}
        borderRadius="5px"
        disabled={isDisabled}
        border="1px solid #C9C9D3"
        placeholder={placeholder}
        size="lg"
        fontSize="1rem"
        fontFamily="Roboto"
        autoComplete="off"
        color="#777781"
        _disabled={{
          opacity: 1,
          backgroundColor: '#F1F1F1',
        }}
        _focus={{
          boxShadow: 'none',
          borderColor: '#0189FF',
          color: '#1a202c',
        }}
        _hover={{
          borderColor: isDisabled ? '' : '#0189FF',
          borderWidth: '1px',
        }}
        _placeholder={{
          color: '#C9C9D3',
          fontStyle: 'italic',
        }}
        onChange={handleInputChange}
        aria-describedby={describedby}
      ></Input>
    </Box>
  )
}
